import { action, makeObservable, observable, toJS } from "mobx";

import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import { IndeedCampaign } from "../models/indeed-manager/IndeedCampaign";
import { PLIndeedData } from "../models/indeed-manager/PLIndeedData";
import { Campaign } from "../models/operation/Campaign";
import { Company } from "../models/operation/Company";
import LocalStorageService from "../services/LocalStorageService";
import campaignService from "../services/operation/CampaignService";

export class IndeedStore {
  /**
   * Indeed data for selected PandoLogic campaign
   */
  @observable
  public PLCampaignData?: PLIndeedData;

  /**
   * The selected indeed campaign
   */
  @observable
  public SelectedIndeedCampaign?: IndeedCampaign;

  /**
   * The selected company
   */
  @observable
  public Company!: Company;

  /**
   * The selected indeed campaign
   */
  @observable
  public IsDataLoading: boolean = false;

  /**
   * Ctor
   */
  constructor() {
    makeObservable(this);
  }

  @action
  public async SetSelectedIndeedCampaign(indeedCampaign?: IndeedCampaign) {
    this.SelectedIndeedCampaign = indeedCampaign;
  }

  @action
  public async SetPLCampaignData(plIndeedData?: PLIndeedData) {
    this.PLCampaignData = plIndeedData;
  }

  @action
  public async SetCompany(company: Company) {
    this.Company = company;
  }

  @action
  public async SetIsDataLoading(state: boolean) {
    this.IsDataLoading = state;
  }
}
