import { Button, message } from "antd";
import { observer } from "mobx-react-lite";
import hash from "object-hash";
import { useContext, useRef } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { Link, useHistory, useParams } from "react-router-dom";

import Loader from "../../../components/general/loader/Loader";
import IndeedCampaignsTable from "../../../components/indeed-manager/IndeedCampaignsTable";
import IndeedStrategyManagement from "../../../components/indeed-manager/strategy/IndeedStrategyManagement";
import { RoutesPaths } from "../../../constants/RoutePaths";
import useOnInitAsync from "../../../hooks/useOnInitAsync";
import useOnPoller from "../../../hooks/useOnPoller";
import usePageInitialization from "../../../hooks/usePageInitialization";
import indeedService from "../../../services/indeed/IndeedService";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import { IndeedContextInitializers } from "./helpers/IndeedContextInitializers";
import { IndeedBreadcrumbs } from "./helpers/IndeedManager.breadcrumbs";

interface Props { }

const IndeedCampaignPage = (props: Props) => {
    const match = useParams<{ campaignId: string }>();
    const history = useHistory();
    const { indeedStore, uiStore } = useContext(RootStoreContext);
    const plDataHash = useRef<string>("");

    useOnInitAsync(async () => {
        await IndeedContextInitializers.InitializePLCampaignData(
            Number(match.campaignId),
            indeedStore,
            history
        );
        uiStore.SetPageTitle(
            `Indeed Campaigns for: ${indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignName}`
        );
        plDataHash.current = hash(indeedStore.PLCampaignData ?? "");
    });

    usePageInitialization({

        onCompanyContextCallBack: (company, uiStore) => {
            /*  uiStore.SetPageTitle(
                  `Indeed Campaigns for: ${indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignName}`
              );*/
            uiStore.AddBreadCrumbs(
                SupervisionBreadcrumbs.Companies(company.companyName),
                SupervisionBreadcrumbs.Campaigns(`${indeedStore.PLCampaignData?.indeedCampaignSourceInfo.campaignName}`),
                IndeedBreadcrumbs.Indeed(company.companyId),
            );
        },

    });

    // Update page if data changed
    useOnPoller(10, async () => {
        const plData = await indeedService.GetIndeedCampaignsByPLCampaignId(
            Number(match.campaignId)
        );

        if (hash(plData) != plDataHash.current) {
            indeedStore.SetPLCampaignData(plData);
            plDataHash.current = hash(plData);
            message.info("Updated table since data has been changed");
        }
    });

    if (indeedStore.IsDataLoading) {
        return <Loader position="normal" />;
    }

    return (
        <div>
            {/* <IndeedSearchSection /> */}

            <IndeedStrategyManagement plCampaignId={match.campaignId} />

            <IndeedCampaignsTable
                indeedCampaigns={indeedStore.PLCampaignData?.indeedCampaigns}
                campaignId={Number(match.campaignId)}
            />

            <div style={{ width: 270 }}>
                <Link
                    to={RoutesPaths.Supervision.IndeedRoutes.BuildAddCampaign(
                        Number(match.campaignId)
                    )}>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        style={{ display: "flex", alignItems: "center" }}>
                        <BiPlusCircle
                            size={20}
                            style={{ marginRight: "10px" }}
                        />
                        <span> Add New Indeed Campaign</span>
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default observer(IndeedCampaignPage);
