
import { APIResponse } from "../../models/APIResponse";
import { IndeedAttachJobsToCampaignEventPayload } from "../../models/indeed-manager/IndeedAttachJobsToCampaignEventPayload";
import { IndeedCampaign } from "../../models/indeed-manager/IndeedCampaign";
import { IndeedCreateCampaignEventPayload } from "../../models/indeed-manager/IndeedCreateCampaignEventPayload";
import { IndeedUpdateCampaignBudgetEventPayload } from "../../models/indeed-manager/IndeedUpdateCampaignBudgetEventPayload";
import { IndeedUpdateCampaignEventPayload } from "../../models/indeed-manager/IndeedUpdateCampaignEventPayload";
import { PLIndeedData } from "../../models/indeed-manager/PLIndeedData";
import { BaseService } from "../BaseService";

export class IndeedService
    extends BaseService {

    /**
    * Gets a list of indeed campaigns by PL campaign ID
    * The campaigns are returned WITHOUT Indeed Job populated
    */
    async GetIndeedCampaignsByPLCampaignId(pandoLogicCampaignId: number) {
        const PLIndeedData = await this.Get<PLIndeedData>(`/get-data/${pandoLogicCampaignId}`);
        return PLIndeedData;
    }


    /**
     * Gets an Indeed campaign by Indeed campaign ID.
     * The campaign is returned with IndeedJobs list
     */
    async GetIndeedCampaignById(indeedCampaignId: string) {
        const indeedCampaign = await this.Get<IndeedCampaign>(`/campaigns/${indeedCampaignId}`);
        return indeedCampaign;
    }

    async CreateCampaign(createCampaignEventPayload: IndeedCreateCampaignEventPayload): Promise<APIResponse<string>> {
        const apiResponse = await this.Post<APIResponse<string>>(`/create-campaign`, createCampaignEventPayload);
        if (apiResponse.isError) {
            throw new Error(apiResponse.body);
        }
        return apiResponse;
    }

    async UpdateCampaign(updateCampaignEventPayload: IndeedUpdateCampaignEventPayload): Promise<APIResponse<string>> {
        const apiResponse = await this.Patch<APIResponse<string>>(`/update-campaign`, updateCampaignEventPayload);
        if (apiResponse.isError) {
            throw new Error(apiResponse.body);
        }
        return apiResponse;
    }

    async UpdateCampaignBudget(updateCampaignBudgetEventPayload: IndeedUpdateCampaignBudgetEventPayload): Promise<APIResponse<string>> {
        const apiResponse = await this.Patch<APIResponse<string>>(`/update-campaign-budget`, updateCampaignBudgetEventPayload);
        if (apiResponse.isError) {
            throw new Error(apiResponse.body);
        }
        return apiResponse;
    }

    async AttachJobsToCampaign(attachJobsToCampaignEventPayload: IndeedAttachJobsToCampaignEventPayload): Promise<APIResponse<string>> {
        const apiResponse = await this.Post<APIResponse<string>>(`/attach-jobs-to-campaign`, attachJobsToCampaignEventPayload);
        if (apiResponse.isError) {
            throw new Error(apiResponse.body);
        }
        return apiResponse;
    }
}

const indeedService = new IndeedService("Indeed");
export default indeedService