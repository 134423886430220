import { Box } from "@material-ui/core";
import { Button, message } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import VendorManagement from "../../../components/vendor-management/VendorManagement";
import VendorSelectInput from "../../../components/vendor-management/VendorSelectInput";
import usePageInitialization from "../../../hooks/usePageInitialization";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { VendorManagmentContextInitializers } from "./helpers/VendorManagmentContextInitializers";
import { VendorManagmentStateContextInitializers } from "./helpers/VendorManagmentStateContextInitializers";
import companyVendorsService from "../../../services/vendor-management/CompanyVendorService";
import { ClassificationTypeValidation } from "./helpers/ClassificationTypeValidation";
import VendorManagmentMode from "../../../components/vendor-management/VendorManagmentMode";
import { ClassificationType } from "../../../models/vendor-management/ClassificationType";
import { SupervisionBreadcrumbs } from "../supervision/breadcrumbs/supervision.breadcrumbs";
import VendorManagmentBreadCrumbs from "./helpers/VendorManagmentBreadCrumbs";
import { RoutesPaths } from "../../../constants/RoutePaths";
import PanelControl from "../../../components/vendor-management/ControlPanael";
import Commands from "../../../components/vendor-management/Commands";
import { ELevelTypes } from "../../../components/actions/ELevelTypes";
import { ReportPage } from "./helpers/ReportPage";
import { EntityDistributionSettings } from "../../../models/vendor-management/settings/EntityDistributionSettings";


interface Props { }

const CompanyVendorPage = (props: Props) => {
  const match = useParams<{ companyId: string }>();
  const { vendorManagementStore, userStore, uiStore } = useContext(RootStoreContext);

  const history = useHistory();

  usePageInitialization({
    onInitAsync: async () => {
      await vendorManagementStore.SetSearchedVendors([]); // reset
      await VendorManagmentStateContextInitializers.InitializeCompanySettingsData(
        Number(match.companyId),
        vendorManagementStore,
        history
      )
      await updateAndSort();
    },

    onCompanyContextCallBack: (company, uiStore) => {
      uiStore.SetPageTitle(`Vendor Managment for: ${company.companyName}`);
      uiStore.AddBreadCrumbs(
        SupervisionBreadcrumbs.Companies(company.companyName),
        VendorManagmentBreadCrumbs.CompanyVendor(company.companyId)
      );
    },
  });

  const onApply = async () => {
    try {
      //vendors record changes
      vendorManagementStore.SetVendorsToApply(
        vendorManagementStore.rowsEffected
      );
      vendorManagementStore.SelectedVendorsToApply.forEach((vendor) => {
        vendor.userId = userStore.User!.id;
      });

      //settings changes
      let entityDistributionSettingsResponse = await companyVendorsService.GetEffectedEntitesChangeModeAsync(
        vendorManagementStore.EntitySettings,
        Number(match.companyId)
      );

      const reportResult = await ReportPage.BuildReport(uiStore,
        vendorManagementStore.rowsEffected, entityDistributionSettingsResponse.resetEntitiesCollections);

      if (reportResult.action) {
        await companyVendorsService.SetCompanyDistributionSettings(
          vendorManagementStore.EntitySettings,
          Number(match.companyId)
        )

        await companyVendorsService.SetCompanysVendors(
          vendorManagementStore.SelectedVendorsToApply, Number(match.companyId), reportResult.sourceRequest);
        vendorManagementStore.rowsEffected = [];

        await updateAndSort();
      }

      else {
        return;
      }

      message.success("Company vendors updated successfully");
      //history.push(RoutesPaths.Supervision.Companies);

    } catch (error) {
      message.error("Company vendors updated failed");
    }
  };


  const updateAndSort = async () => {
    await VendorManagmentContextInitializers.InitializeCompanyVendorsData(
      Number(match.companyId),
      vendorManagementStore,
      history
    );

    let isWhitelist = vendorManagementStore.EntitySettings.classificationType == ClassificationType.Exclusive

    vendorManagementStore.SearchedVendors =
      vendorManagementStore.Vendors?.filter(
        (vendor) =>
          vendor.classificationType != ClassificationType.None ||
          vendor.inclusionEnforcement !== false || !isWhitelist && vendor.isOperativeCutoff
      ).sort((a, b) => {
        if (!a.isOperativeCutoff && b.isOperativeCutoff) {
          return -1; // `b` comes before `a`
        } else if (!a.isOperativeCutoff && b.isOperativeCutoff) {
          return 1;  // `a` comes before `b`
        } else {
          return 0; // no change in order
        }
      });
  }



const onReset = async () => {
  try {
    await companyVendorsService.ResetCompanyVendors(Number(match.companyId));
    message.success("Company vendors reset successfully");
    history.push(RoutesPaths.Supervision.Companies);

  } catch (error) {
    message.error("Company vendors reset failed");
  }
};

const onVaild = async (newSettings: EntityDistributionSettings) => {

  return await companyVendorsService.ChangeSettingsIsValidValidationAsync(
    newSettings,
    Number(newSettings.entityId)
  );
}

return (
  <div>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <VendorSelectInput entityVendors={vendorManagementStore.Vendors} />
      <PanelControl onValid={onVaild} />
    </Box>
    <VendorManagement />
    <Commands onApply={onApply} onReset={onReset} />
  </div>
);
};

export default observer(CompanyVendorPage);
